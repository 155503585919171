import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { Role } from 'interfaces/user-roles';
import { selectRole } from 'base/auth/selectors';
import { AuthorityLevels, Permissions } from 'config';
import API from './constants';
import {
  selectChargesForm,
  selectContentForm,
  selectEntUrl,
  selectGeneralInformationForm,
  selectId,
  selectSitePreferenceForm,
} from './selector';
import { onboardEntAccActions } from './slice';
import { ICreateAdminModalForm } from '../edit-account/components/admins/components/create-admin/interface';
import { AdminDocument } from '../edit-account/components/admins/interface';
import { ExpertDocument } from '../edit-account/components/experts/interface';

export function* getEntAccGenerator(): any {
  try {
    const id = yield select(selectId);
    const role = yield select(selectRole);
    const response: IRestApiResponse = yield call(
      request,
      { path: role === Role.EntAccountAdmin ? API.GET_ENT_ACC.path : `${API.GET_ENT_ACC.path}/${id}`, method: API.GET_ENT_ACC.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(onboardEntAccActions.getEntAccSucceeded(response.data));
    } else {
      yield put(onboardEntAccActions.getEntAccFailed());
    }
  } catch (error) {
    yield put(onboardEntAccActions.getEntAccFailed());
  }
}

export function* getAdminByEntGenerator(): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.GET_ADMIN_BY_ENT,
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(
        onboardEntAccActions.getAdminByEntSucceded(
          (response.data.result as AdminDocument[]).map((i) => ({ ...i })),
        ),
      );
    } else {
      yield put(onboardEntAccActions.getAdminByEntFailed());
    }
  } catch (error) {
    yield put(onboardEntAccActions.getAdminByEntFailed());
  }
}

export function* getExpertByEntGenerator(): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_EXPERTS_BY_ENT.path}/${id}`, method: API.GET_EXPERTS_BY_ENT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(
        onboardEntAccActions.getExpertByEntSucceded(
          (response.data as ExpertDocument[]).map((i) => ({ ...i })),
        ),
      );
    } else {
      yield put(onboardEntAccActions.getExpertByEntFailed());
    }
  } catch (error) {
    yield put(onboardEntAccActions.getExpertByEntFailed());
  }
}
export function* getStripConnectUrlGenerator(): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      API.POST_STRIPE_CONNECT_URL,
      { returnRoute: 'general-information' },
      true,
    );
    if (response.statusCode === 201) {
      yield put(onboardEntAccActions.getStripConnectUrlSucceeded(response.data.url));
    } else {
      yield put(onboardEntAccActions.getStripConnectUrlFailed());
    }
  } catch (error) {
    yield put(onboardEntAccActions.getStripConnectUrlFailed());
  }
}

function* createAdminsGenerator(
  action: PayloadAction<
  { firstname: string; lastname: string; email: string; authority: string }[]
  >,
) {
  const entId: string = yield select(selectId);
  const entUrl: string = yield select(selectEntUrl);
  const profileImgUrl = '';
  const { payload } = action;
  const roles = ['accessClasses', 'observeSessions', 'authenticateComplaints', 'accessRecordings'];

  for (let i = 0; i < payload.length; i += 1) {
    yield call(
      request,
      API.POST_CREATE_ADMIN,
      {
        entUrl,
        entId,
        profileImgUrl,
        firstName: payload[i].firstname,
        lastName: payload[i].lastname,
        email: payload[i].email,
        attributes: { authorityLevel: payload[i].authority, isActive: true },
        permissions: roles,
      },
      true,
    );
  }

  yield put(onboardEntAccActions.getAdminByEnt());
}

function* createAdminGenerator(action: PayloadAction<ICreateAdminModalForm>) {
  try {
    const entUrl: string = yield select(selectEntUrl);
    const entId: string = yield select(selectId);
    const { payload } = action;
    const roles = Object.keys(payload).filter(
      (el: string) => payload[el as keyof ICreateAdminModalForm] === true,
    );

    yield call(
      request,
      API.POST_CREATE_ADMIN,
      {
        entUrl,
        entId,
        profileImgUrl: payload.profileImgUrl,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        attributes: { authorityLevel: payload.authorityLevel },
        permissions: roles,
      },
      true,
    );

    yield put(onboardEntAccActions.getAdminByEnt());
    yield put(onboardEntAccActions.closeModal());
  } catch (error) {
    yield put(onboardEntAccActions.createAdminFailed());
  }
}

function* deleteAdminGenerator(action: PayloadAction<AdminDocument>) {
  const { payload } = action;

  try {
    yield call(
      request,
      API.DELETE_ADMIN,
      {
        userId: payload.id,
      },
      true,
    );

    yield put(onboardEntAccActions.getAdminByEnt());
  } catch {
    yield put(onboardEntAccActions.deleteAdminFailed());
  }
}

function* changeAdminAuthorityLevelGenerator(
  action: PayloadAction<{ id: string; authorityLevel: string, permissions: string[] }>,
) {
  const { payload } = action;
  const { permissions: perm, id, authorityLevel } = payload;
  const permissions = [...perm];

  if (payload.authorityLevel === AuthorityLevels.CLIENT_ADMIN) {
    permissions.push(
      ...[
        Permissions.generalInformation,
        Permissions.groups,
        Permissions.sessions,
        Permissions.experts,
        Permissions.users,
        Permissions.admins,
      ],
    );
  }
  try {
    yield call(
      request,
      API.PATCH_PERMISSIONS_ADMIN,
      {
        id,
        accountType: Role.EntAccountAdmin,
        authorityLevel,
        permissions: permissions.filter((value, index, self) => self.indexOf(value) === index),
      },
      true,
    );
    yield put(onboardEntAccActions.getAdminByEnt());
  } catch {
    yield put(onboardEntAccActions.getAdminByEnt());
  }
}

function* inviteExpertsGenerator(
  action: PayloadAction<{ email: string; }[]>,
) {
  try {
    const entId: string = yield select(selectId);
    const entUrl: string = yield select(selectEntUrl);
    const { payload } = action;

    for (let i = 0; i < payload.length; i += 1) {
      yield call(
        request,
        API.POST_INVITE_EXPERT,
        {
          entUrl,
          entId: [entId],
          isActive: true,
          attributes: { isActive: true },
          ...action.payload[i],
        },
        true,
      );
    }

    yield put(onboardEntAccActions.getExpertByEnt());
  } catch (error) {
    yield put(onboardEntAccActions.inviteExpertFailed());
  }
}

function* inviteExpertGenerator(
  action: PayloadAction<{
    email: string;
    isActive: boolean;
  }>,
) {
  try {
    const entId: string = yield select(selectId);
    const entUrl: string = yield select(selectEntUrl);
    const { payload } = action;

    const response: IRestApiResponse = yield call(
      request,
      API.POST_INVITE_EXPERT,
      {
        entUrl,
        entId: [entId],
        isActive: true,
        email: payload.email,
      },
      true,
    );

    if (response.statusCode === 201) {
      yield put(onboardEntAccActions.getExpertByEnt());
    } else {
      yield put(onboardEntAccActions.inviteExpertFailed());
    }
  } catch (error) {
    yield put(onboardEntAccActions.inviteExpertFailed());
  }
}

function* deleteExpertGenerator(action: PayloadAction<ExpertDocument>) {
  try {
    const { payload } = action;

    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_EXPERT,
      {
        id: payload.id,
        email: payload.email,
      },
      true,
    );
    if (response.statusCode === 200) {
      yield put(onboardEntAccActions.getExpertByEnt());
    } else {
      yield put(onboardEntAccActions.deleteExpertFailed());
    }
  } catch (error) {
    yield put(onboardEntAccActions.deleteExpertFailed());
  }
}

function* updateGeneralInformationGenerator(): any {
  try {
    const id = yield select(selectId);
    const {
      country, city, facebookUrl, instagramUrl, linkedinUrl, youtubeUrl,
      twitterUrl, webLogoUrl, entLogoUrl, categories, skills, currency,
    } = yield select(selectGeneralInformationForm);

    const categoryResponse: IRestApiResponse = yield call(
      request,
      API.POST_CATEGORIES,
      { data: categories.map((i: { id: any; name: any; }) => ({ id: i.id, name: i.name })) },
      true,
    );

    if (categoryResponse.statusCode !== 201) {
      yield put(onboardEntAccActions.editGeneralInformationFailed());
      return;
    }

    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      {
        country,
        city,
        currency,
        facebookUrl,
        instagramUrl,
        linkedinUrl,
        youtubeUrl,
        twitterUrl,
        webLogoUrl,
        entLogoUrl,
        skills,
        id,
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(onboardEntAccActions.editGeneralInformationSucceeded());
    } else {
      yield put(onboardEntAccActions.editGeneralInformationFailed());
    }
  } catch (error) {
    yield put(onboardEntAccActions.editGeneralInformationFailed());
  }
}

function* updateIsOnboardingDoneGenerator(): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      {
        isOnBoardingDone: true,
        id,
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(onboardEntAccActions.isOnboardingSucceeded());
    } else {
      yield put(onboardEntAccActions.isOnboardingFailed());
    }
  } catch (error) {
    yield put(onboardEntAccActions.isOnboardingFailed());
  }
}

function* updateSitePreferencesGenerator(): any {
  try {
    const id = yield select(selectId);
    const onboardSitePreferencesForm = yield select(selectSitePreferenceForm);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      {
        ...onboardSitePreferencesForm,
        promotionalContent: [{ ...onboardSitePreferencesForm.promotionalContent }],
        id,
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(onboardEntAccActions.editSitePreferencesSucceeded());
    } else {
      yield put(onboardEntAccActions.editSitePreferencesFailed());
    }
  } catch (error) {
    yield put(onboardEntAccActions.editSitePreferencesFailed());
  }
}

function* updateContentsGenerator(): any {
  try {
    const id = yield select(selectId);
    const onboardContentsForm = yield select(selectContentForm);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      {
        ...onboardContentsForm,
        id,
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(onboardEntAccActions.editContentsSucceeded());
    } else {
      yield put(onboardEntAccActions.editContentsFailed());
    }
  } catch (error) {
    yield put(onboardEntAccActions.editContentsFailed());
  }
}

function* updateChargesGenerator(): any {
  try {
    const id = yield select(selectId);
    const onboardChargesForm = yield select(selectChargesForm);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_ENT_ACC,
      {
        charges: onboardChargesForm.charges,
        id,
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(onboardEntAccActions.editChargesSucceeded());
    } else {
      yield put(onboardEntAccActions.editChargesFailed());
    }
  } catch (error) {
    yield put(onboardEntAccActions.editChargesFailed());
  }
}

export function* onboardEntAccSaga() {
  yield all([
    takeLatest(onboardEntAccActions.getEntAcc.type, getEntAccGenerator),
    takeLatest(onboardEntAccActions.getAdminByEnt.type, getAdminByEntGenerator),
    takeLatest(onboardEntAccActions.getExpertByEnt.type, getExpertByEntGenerator),
    takeLatest(onboardEntAccActions.getStripConnectUrl.type, getStripConnectUrlGenerator),
    takeLatest(onboardEntAccActions.createAdmins.type, createAdminsGenerator),
    takeLatest(onboardEntAccActions.createAdmin.type, createAdminGenerator),
    takeLatest(onboardEntAccActions.deleteAdmin.type, deleteAdminGenerator),
    takeLatest(
      onboardEntAccActions.changeAdminAuthorityLevel.type,
      changeAdminAuthorityLevelGenerator,
    ),
    takeLatest(onboardEntAccActions.inviteExpert.type, inviteExpertGenerator),
    takeLatest(onboardEntAccActions.inviteExperts.type, inviteExpertsGenerator),
    takeLatest(onboardEntAccActions.deleteExpert.type, deleteExpertGenerator),
    takeLatest(onboardEntAccActions.editGeneralInformation.type, updateGeneralInformationGenerator),
    takeLatest(onboardEntAccActions.editSitePreferences.type, updateSitePreferencesGenerator),
    takeLatest(onboardEntAccActions.editContents.type, updateContentsGenerator),
    takeLatest(onboardEntAccActions.editCharges.type, updateChargesGenerator),
    takeLatest(onboardEntAccActions.isOnboarding.type, updateIsOnboardingDoneGenerator),
  ]);
}

export default onboardEntAccSaga;
